import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Protein from '../../Assets/Proteins/small_mol_5.png'
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Docking from '../../Assets/Logos/DockingBlue.png'
import VirtualScreening from '../../Assets/Logos/VSBlue.png'
import AutoFEP from '../../Assets/Logos/FEPBlue.png'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  boxShadow: 'none',
  height: '100%'
}));

export default function SmallMoleculeSection() {
  return (
    <Box 

    sx={{ display: 'flex' }}  
    style={{
      background: "linear-gradient(white, transparent), linear-gradient(to top left, #2dc4b6, transparent), linear-gradient(to top right, white, transparent)",
        backgroundBlendMode: "screen"
    }}>
    <CssBaseline />
    <Container >
    <Box sx={{ flexGrow: 1}}
      id="#smallmolecule">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} >
        <Hidden mdDown>
          <Typography align='left' gutterBottom fontSize='4.5em' color='#171941' marginTop='20vw' lineHeight = "1.2" >
            <b>Small Molecules</b>
            </Typography>
            <Typography align='left' fontSize='1.3em' lineHeight = "1.4" color='#171941'>
            <b>The Prometheus Platform offers a fully automated, end-to-end solution for small molecule discovery and optimization.</b>
            </Typography>
           
        </Hidden>
        <Hidden mdUp>
            <Typography align='left' gutterBottom fontSize='4.5em' color='#171941' marginTop='40vw' lineHeight = "1.2" >
            <b>Small Molecules</b>
            </Typography>
            <Typography align='left' fontSize='1.3em' lineHeight = "1.4" color='#171941'>
            <b>The Prometheus Platform offers a fully automated, end-to-end solution for small molecule discovery and optimization.</b>
            </Typography>
          </Hidden>
        </Grid>
        <Hidden mdDown>
            <Grid item md={6} >
            <img src={Protein} width='100%' alt="Sentauri Logo" style={{marginTop:'15vw'}}/>
            </Grid>
        </Hidden>
      </Grid>
      
    </Box>
    <Box sx={{ flexGrow: 1 }} style = {{paddingTop: '15vw', paddingBottom: '10vw'}}>
      <Grid container spacing={2}   
      direction="row"
    justifyContent="center"
      alignItems="stretch">
      <Grid item xs={12} >
          <Typography align='center' gutterBottom fontSize='3.5em' color='#171941' lineHeight = "1.2" style = {{paddingBottom: '2vw'}}>
            <b>The Prometheus Platform</b>
            </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <Item>
            <Typography align='center' gutterBottom fontSize='2.5em' color='#171941' style={{padding: '10px'}}>
            <b>Virtual Screening</b>
            </Typography>
            <img src={VirtualScreening} height='150px' alt="Jack Ferrie" style={{padding: '0% 20%',}}/>
            <Typography align='center' gutterBottom fontSize='1.2em' color='#171941' style={{padding: '10px'}}>
           Screen billions of compounds in minutes to identify promising candidates.
            </Typography>
            </Item>
          </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <Item>
            <Typography align='center' gutterBottom fontSize='2.5em' color='#171941' style={{padding: '10px'}}>
            <b>Docking</b>
            </Typography>
            <img src={Docking} height='150px' alt="Jack Ferrie" style={{padding: '0% 20%',}}/>
            <Typography align='center' gutterBottom fontSize='1.2em' color='#171941' style={{padding: '10px'}}>
            Accurately dock molecules to understand structure-activity relationships (SAR).
            </Typography>
            </Item>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Item>
            <Typography align='center' gutterBottom fontSize='2.5em' color='#171941' style={{padding: '10px'}}>
            <b>FEP</b>
            </Typography>
            <img src={AutoFEP} height='150px' alt="Jack Ferrie" style={{padding: '0% 20%',}}/>
            <Typography align='center' gutterBottom fontSize='1.2em' color='#171941' style={{padding: '10px'}}>
            Harness state-of-the-art Free Energy Perturbation (FEP) calculations for ligand optimization.
            </Typography>
            </Item>
          </Grid>
      </Grid>
    </Box>
    </Container>
    </Box>
  );
}
