import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../../Components/Navbar';
import HeroSection from './Hero';
import HowItWorks from './HowItWorks';
import ContactUs from '../../Components/ContactUs'
import Footer from '../../Components/Footer'

// #171941 blue
// #ff3366 pink
// #a69041 gold
// #2dc4b6 green

function Home() {

  return (
    <>
    <Box sx={{ display: 'flex' }}  
    style={{
        height: '120vh',
        background: "linear-gradient(#171941, #171941), linear-gradient(to top left, #ff3366, #2dc4b6), linear-gradient(to top right, #171941, transparent)",
        backgroundBlendMode: "screen"
    }}>
        <CssBaseline />
        <Navbar/>
        <HeroSection />
    </Box>
    
    <HowItWorks/>
    {/* <BiologicsSection/> */}
    {/* <ServicesSection/> */}
    {/* <CompanySection/> */}
    <ContactUs/>
    <Footer/>
    </>
  );
}

export default Home;
