import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../../Components/Navbar';
import HeroSection from './HeroSection';
import SmallMoleculeSection from './SmallMoleculeSection';
import BiologicsSection from './BiologicsSection';
// import ServicesSection from './ServicesSection'
import CompanySection from './CompanySection'
import ContactUs from '../../Components/ContactUs'
import Footer from '../../Components/Footer'

// #171941 blue
// #ff3366 pink
// #a69041 gold
// #2dc4b6 green

function Home(props) {

  return (
    <>
    <Box sx={{ display: 'flex' }}  
    style={{
        height: '120vh',
        background: "linear-gradient(#171941, #171941), linear-gradient(to top left, #2dc4b6, transparent), linear-gradient(to top right, #171941, transparent)",
        backgroundBlendMode: "screen"
    }}>
        <CssBaseline />
        <Navbar/>
        <HeroSection />
    </Box>
    
    <SmallMoleculeSection/>
    <BiologicsSection/>
    {/* <ServicesSection/> */}
    <CompanySection/>
    <ContactUs/>
    <Footer/>
    </>
  );
}

export default Home;
