import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Protein from '../../Assets/Proteins/Antibody_Smooth.gif'
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  boxShadow: 'none',
  height: '100%'
}));

export default function SmallMoleculeSection() {
  return (
    <Box 

    sx={{ display: 'flex' }}  
    style={{
        background: "linear-gradient(0.25turn, transparent, transparent), linear-gradient(to top left, #2dc4b6, transparent), linear-gradient(to top right, white, transparent)",
        backgroundBlendMode: "screen"
    }}>
    <CssBaseline />
    <Container >
    <Box sx={{ flexGrow: 1}}
      id="#smallmolecule">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} >
        <Hidden mdDown>
          <Typography align='left' gutterBottom fontSize='4.5em' color='#171941' marginTop='20vw' lineHeight = "1.2" >
            <b>How It Works</b>
            </Typography>
            <Typography align='left' fontSize='1.3em' lineHeight = "1.4" color='#171941'>
            <b>The easiest and most secure way to obtain a structure of your protein, fast and at scale</b>
            </Typography>
           
        </Hidden>
        <Hidden mdUp>
            <Typography align='left' gutterBottom fontSize='4.5em' color='#171941' marginTop='40vw' lineHeight = "1.2" >
            <b>How It Works</b>
            </Typography>
            <Typography align='left' fontSize='1.3em' lineHeight = "1.4" color='#171941'>
            <b>The easiest and most cost effective way to obtain a structure of your protein - fast and at scale</b>
            </Typography>
          </Hidden>
        </Grid>
        <Hidden mdDown>
            <Grid item md={6} >
            <img src={Protein} width='100%' alt="Sentauri Logo" style={{marginTop:'15vw'}}/>
            </Grid>
        </Hidden>
      </Grid>
      
    </Box>
    <Box sx={{ flexGrow: 1 }} style = {{paddingTop: '10vw', paddingBottom: '10vw'}}>
      <Grid container spacing={2}   
      direction="row"
    justifyContent="center"
      alignItems="stretch">
      <Grid item xs={12} >
          <Typography align='center' gutterBottom fontSize='3em' color='#171941' lineHeight = "1.2" style = {{paddingBottom: '2vw'}}>
            <b>Simplified Access to Advanced Protein Folding</b>
            </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <Item>
            <Typography align='center' gutterBottom fontSize='2.5em' color='#171941' style={{padding: '10px'}}>
            <b>Affordable</b>
            </Typography>
            {/* <img src={VirtualScreening} height='150px' alt="Jack Ferrie" style={{padding: '0% 20%',}}/> */}
            <Typography align='center' gutterBottom fontSize='1.2em' color='#171941' style={{padding: '10px'}}>
            <b>$500 per protein. For proteins larger than 1000 residues, multiple charges will be incurred</b>
            </Typography>
            </Item>
          </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <Item>
            <Typography align='center' gutterBottom fontSize='2.5em' color='#171941' style={{padding: '10px'}}>
            <b>Secure</b>
            </Typography>
            {/* <img src={Docking} height='150px' alt="Jack Ferrie" style={{padding: '0% 20%',}}/> */}
            <Typography align='center' gutterBottom fontSize='1.2em' color='#171941' style={{padding: '10px'}}>
            <b>We will comminicate an industry standard CDA to protect your intellectual property, or we will sign one that you provide.</b>
            </Typography>
            </Item>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Item>
            <Typography align='center' gutterBottom fontSize='2.5em' color='#171941' style={{padding: '10px'}}>
            <b>Fast</b>
            </Typography>
            {/* <img src={AutoFEP} height='150px' alt="Jack Ferrie" style={{padding: '0% 20%',}}/> */}
            <Typography align='center' gutterBottom fontSize='1.2em' color='#171941' style={{padding: '10px'}}>
            <b>Once we've recieved your sequence, we will return a structure to you the next day. </b>
            </Typography>
            </Item>
          </Grid>
      </Grid>
    </Box>
    </Container>
    </Box>
  );
}
