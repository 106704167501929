import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Protein from '../../Assets/Proteins/ProteinSix.png'
import Hidden from '@mui/material/Hidden';

export default function HeroSection() {
  return (
    <React.Fragment>
    <CssBaseline />
    <Container >
    <Box sx={{ flexGrow: 1}} >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} >
        <Hidden mdDown>
          <Typography align='left' gutterBottom fontSize='4.2em' color='white' marginTop='20vw' lineHeight = "1.2" >
            <b>Fold Your Protein</b>
            </Typography>
            <Typography align='left' fontSize='1.3em' lineHeight = "1.4" color='WhiteSmoke'>
            <b>Sentauri uses cutting edge technologies like RosettaFold to find the best structure for your sequence</b>
            </Typography>
           
        </Hidden>
        <Hidden mdUp>
            <Typography align='left' gutterBottom fontSize='4.2em' color='white' marginTop='50vw' lineHeight = "1.2" >
            <b>Fold Your Protein</b>
            </Typography>
            <Typography align='left' fontSize='1.3em' lineHeight = "1.4" color='WhiteSmoke'>
            <b>Sentauri uses cutting edge technologies like RosettaFold to find the best structure for your sequence</b>
            </Typography>
          </Hidden>
        </Grid>
        <Hidden mdDown>
            <Grid item md={6} >
            <img src={Protein} width='100%' alt="Sentauri Logo" style={{marginTop:'5vw'}}/>
            </Grid>
        </Hidden>
      </Grid>
    </Box>
    </Container>
    </React.Fragment>
  );
}
