import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Logo from '../Assets/Logos/WhiteSentauriLogo_MonochromeThin.png';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';



// const navItems = ['Platform', 'Small Molecule', 'Biologics', 'Services', 'Company'];

// #171941 blue
// #ff3366 pink
// #a69041 gold
// #2dc4b6 green



export default function SmallMoleculeSection(children) {
  return (
    <Box 
    // sx={{ display: 'flex' }}  
    sx={{
        // borderTop: "1px solid #000",
        marginTop: "auto",
        p: 4,
      }}
      component="footer"
    style={{
        background: '#171941',
        backgroundBlendMode: "screen"
    }}
    >
    <Toolbar 
        sx={{
        // borderTop: "1px solid #000",
        marginTop: "auto",
        p: 4,
      }}
        style={{ 
            backgroundColor: 'transparent', 
            transition: "background-color 0.3s ease",
        }}
    >
            <Grid container spacing={2}>
        <Grid item xs={4} >
        <Typography
        variant="h6"
        component="div"e
        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block', color: 'black' } }}
      >
        <img src={Logo} alt="Sentauri Logo" width="240" style={{paddingRight: '2vw'}}/>
      </Typography>
        </Grid>
        <Grid item xs={4} >
        <Typography
        variant="h6"
        component="div"e
        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block', color: 'white' } }}
      >
           ©2024 Sentauri, Inc. All rights reserved.
      </Typography>
        </Grid>
      {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
      <Stack spacing={2}>
        {navItems.map((item) => (
        <Button key={item} sx={{ color: 'white', paddingRight: '2vw' }}>
            <b>{item}</b>
          </Button>
        ))}
        </Stack>
      </Box> */}
      </Grid>
    </Toolbar>
  </Box>
  );
}
