import * as React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
// import Protein from '../../Assets/Proteins/antibody.png'
import Protein from '../../Assets/Proteins/Antibody_Smooth.gif'
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import Expression from '../../Assets/Logos/ExpressionBlue.png'
import Antibody from '../../Assets/Logos/AntibodyBlue.png'
import Localized from '../../Assets/Logos/LocalizedBlue.png'


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  boxShadow: 'none',
  height: '100%'
}));

export default function SmallMoleculeSection(children) {
  return (
    <Box sx={{ display: 'flex' }}  
    style={{
        background: "linear-gradient(0.25turn, transparent, transparent), linear-gradient(to top left, #2dc4b6, transparent), linear-gradient(to top right, white, transparent)",
        backgroundBlendMode: "screen"
    }}>
    <CssBaseline />
    <Container >
    <Box sx={{ flexGrow: 1}} >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} >
        <Hidden mdDown>
          <Typography align='left' gutterBottom fontSize='4.5em' color='#171941' marginTop='10vw' lineHeight = "1.2" >
            <b>Biologics</b>
            </Typography>
            <Typography align='left' fontSize='1.3em' lineHeight = "1.4" color='#171941'>
            <b>Our ProtIQ platform provides automated tools for efficient biologics design and development.</b>
            </Typography>
        </Hidden>
        <Hidden mdUp>
            <Typography align='left' gutterBottom fontSize='4.5em' color='#171941' marginTop='50vw' lineHeight = "1.2" >
            <b>Biologics</b>
            </Typography>
            <Typography align='left' fontSize='1.3em' lineHeight = "1.4" color='#171941'>
            <b>Our ProtIQ platform provides automated tools for efficient biologics design and development.</b>
            </Typography>
          </Hidden>
        </Grid>
        <Hidden mdDown>
            <Grid item md={6} >
            <img src={Protein} width='100%' alt="Sentauri Logo" style={{marginTop:'5vw'}}/>
            </Grid>
        </Hidden>
      </Grid>
    </Box>
    <Box sx={{ flexGrow: 1 }} style = {{paddingTop: '10vw', paddingBottom: '10vw'}}>
      <Grid container spacing={2}   
      direction="row"
    justifyContent="center"
      alignItems="stretch">
      <Grid item xs={12} >
          <Typography align='center' gutterBottom fontSize='3.5em' color='#171941' lineHeight = "1.2" style = {{paddingBottom: '2vw'}}>
            <b>The ProtIQ Platform</b>
            </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <Item>
            <Typography align='center' gutterBottom fontSize='2.0em' color='#171941' style={{padding: '10px'}}>
            <b>Maximize Antibody Developability</b>
            </Typography>
            <img src={Antibody} height='150px' alt="Jack Ferrie" style={{padding: '0% 20%',}}/>
            <Typography align='center' gutterBottom fontSize='1.2em' color='#171941' style={{padding: '10px'}}>
            Improve your antibodies by boosting thermostability, reducing aggregation, increasing solubility, and minimizing antigenicity.
            </Typography>
            </Item>
          </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <Item>
            <Typography align='center' gutterBottom fontSize='2.0em' color='#171941' style={{padding: '10px'}}>
            <b>Optimize Protein Expression</b>
            </Typography>
            <img src={Expression} height='150px' alt="Jack Ferrie" style={{padding: '0% 20%',}}/>
            <Typography align='center' gutterBottom fontSize='1.2em' color='#171941' style={{padding: '10px'}}>
            Leverage AI to select the correct expression systems and develop optimal purification strategies.
            </Typography>
            </Item>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Item>
            <Typography align='center' gutterBottom fontSize='2.0em' color='#171941' style={{padding: '10px'}}>
            <b>Characterize Unexplored Proteins</b>
            </Typography>
            <img src={Localized} height='150px' alt="Jack Ferrie" style={{padding: '0% 20%',}}/>
            <Typography align='center' gutterBottom fontSize='1.2em' color='#171941' style={{padding: '10px'}}>
            Harness our cutting-edge ML technologies to gain detailed insights into the structure and function of uncharacterized proteins.
            </Typography>
            </Item>
          </Grid>
      </Grid>
    </Box>

    </Container>
    </Box>
  );
}
