import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Logo from '../Assets/Logos/WhiteSentauriLogo_MonochromeThin.png';
import BlueLogo from '../Assets/Logos/BlueSentauriLogo.png';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

function Navbar(props) {
  const navigate = useNavigate()

	const handleNavigation = (link) => {
		navigate(link)
	}
// const { window } = props;
const [mobileOpen, setMobileOpen] = React.useState(false);
const [scrollPosition, setScrollPosition] = React.useState(true);

const handleScroll = () => {
    const newScrollPosition = window.scrollY;
    console.log('Scroll position:', newScrollPosition);
    setScrollPosition(newScrollPosition)
  };


useEffect(() => {
    // Add event listener to the window
    window.addEventListener('scroll', handleScroll);

    // Remove event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


const handleDrawerToggle = () => {
  setMobileOpen((prevState) => !prevState);
};

const drawer = (
  <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
    <Typography variant="h6" sx={{ my: 2 }}>
      Sentauri
    </Typography>
    <Divider />
    <List>
    <ListItem key={'Home'} disablePadding onClick={()=>handleNavigation('/')}>
          <ListItemButton sx={{ textAlign: 'center' }} >
            <ListItemText primary={"Home"} />
          </ListItemButton>
        </ListItem>
      <ListItem key={'Home'} disablePadding  onClick={()=>handleNavigation('/folding')}>
          <ListItemButton sx={{ textAlign: 'center' }} >
            <ListItemText primary={"Folding"} />
          </ListItemButton>
        </ListItem>

    </List>
  </Box>
);

const container = props.window !== undefined ? () => window().document.body : undefined;


return(
    <>
<AppBar component="nav" 
style={{ 
    background: 'transparent', 
    // background: '#171941',
    // boxShadow:  scrollPosition < 50 ? 'none' : 'initial',
    boxShadow: 'none',
    transition: "0.3s ease",
    }}>
    <Toolbar 
        style={{ 
            backgroundColor: scrollPosition < 50 ? 'transparent' : 'white', 
            // backgroundColor: scrollPosition < 50 ? 'transparent' : '#2dc4b6', 
            transition: "background-color 0.3s ease",
        }}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: 'none' } }}
      >
        <MenuIcon style={{ color: scrollPosition < 50 ? 'white' : '#171941'}}/>
      </IconButton>
      <Typography
        variant="h6"
        component="div"
        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block', color: 'black' } }}
      >
        <img src={scrollPosition < 50 ? Logo : BlueLogo} alt="Sentauri Logo" width="240" style={{paddingRight: '2vw', cursor: "pointer"}} onClick={()=>handleNavigation('/')}/>
      </Typography>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }} >
        <Button key={'smallmolecule'} sx={{ color: scrollPosition < 50 ? 'white' : '#171941', paddingRight: '2vw' }}  onClick={()=>handleNavigation('/folding')} >
            <b>Folding</b>
          </Button>
      </Box>
    </Toolbar>
  </AppBar>
  <nav>
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { sm: 'block', },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
    >
      {drawer}
    </Drawer>
  </nav>
  </>

)
    }

export default Navbar;