import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Folding from "./Pages/Folding/Main";
import ScrollToTop from "./Components/ScrollToTop";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-XN9P68JPZG"; // your Measurement ID

export default function App() {
	useEffect(() => {
		ReactGA.initialize(TRACKING_ID);
		ReactGA.send({
			hitType: "pageview",
			page: "/",
			title: "Landing Page",
		});
	}, []);

	return (
		<Router>
			<ScrollToTop />
			<Routes>
				<Route path="/folding" element={<Folding />} />
				<Route path="*" element={<Home />} />
			</Routes>
		</Router>
	);
}
