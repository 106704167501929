import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

// #171941 blue
// #ff3366 pink
// #a69041 gold
// #2dc4b6 green

export default function SmallMoleculeSection(children) {
  return (
    <Box 
    // sx={{ display: 'flex' }}  
    sx={{
        // borderTop: "1px solid #000",
        marginTop: "auto",
        p: 4,
      }}
      style={{
        minHeight: '30vw',
        background: '#171941',
        backgroundBlendMode: "screen"
    }}>
    <CssBaseline />
    <Container >
    <Box sx={{ flexGrow: 1}} >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align='center' gutterBottom fontSize='4.5em' color='white' marginTop='5vw' lineHeight = "1.2" >
            <b>Contact Us</b>
            </Typography>
            <Typography align='center' fontSize='1em' lineHeight = "1.4" color='white'>
            <b>Reach out to our co-founder and CBO directly:</b>
            </Typography>
            <br/>
            <Typography align='center' fontSize='1.4em' lineHeight = "1.4" color='#2dc4b6'>
            <b>AndrewApicello@SentauriAi.com</b>
            </Typography>
            </Grid>
      </Grid>
    </Box>
    </Container>
    </Box>
  );
}
