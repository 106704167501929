import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Jack from '../../Assets/Pictures/JackFerrie.png'
import Andrew from '../../Assets/Pictures/Andrew.jpeg'
import Sam from '../../Assets/Pictures/SamGiannakoulias.png'

// #171941 blue
// #ff3366 pink
// #a69041 gold
// #2dc4b6 green

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  boxShadow: 'none',
}));

export default function CompanySection(children) {
  return (
    <Box sx={{ display: 'flex' }}  
    style={{
        background: "linear-gradient(#2dc4b6, transparent), linear-gradient(to top left, transparent, white), linear-gradient(to top right, white, transparent )",
        backgroundBlendMode: "screen"
    }}>
    <CssBaseline />
    <Container >
    <Box sx={{ flexGrow: 1}} >
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Typography align='center' gutterBottom fontSize='3.5em' color='#171941' marginTop='5vw' marginBottom='5vw' lineHeight = "1.2" >
            <b>Our Mission</b>
            </Typography>
            <Typography align='center' fontSize='1.3em' lineHeight = "1.4" color='#171941'>
            <b>Our mission is to empower scientists with easy-to-use, state-of-the-art computational tools. By automating the deep computational expertise often required to use these tools and providing new methodological innovations, we enable researchers to dedicate their time and skills to solving the most complex problems in the field.</b>
            </Typography>
            </Grid>
        </Grid>
    </Box>
    <Box sx={{ flexGrow: 1 }} style = {{paddingTop: '15vw', paddingBottom: '10vw'}}>
      <Grid container spacing={2}>
      <Grid item xs={12} >
          <Typography align='center' gutterBottom fontSize='3.5em' color='#171941' lineHeight = "1.2" style = {{paddingBottom: '5vw'}}>
            <b>Our Leadership Team</b>
            </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
            <Item>
            <img src={Jack} width='100%' alt="Jack Ferrie" style={{padding: '0% 20%', borderRadius:'50%'}}/>
            <Typography align='center' gutterBottom fontSize='1.5em' color='#171941' style={{padding: '10px'}}>
            <b>Jack Ferrie, CEO</b>
            </Typography>
            <Typography align='center' gutterBottom fontSize='1.2em' color='#171941' style={{padding: '10px'}}>
            Jack Ferrie has a diverse background spanning chemical biology, molecular and cell biology, and computational chemistry. Jack completed his PhD in Chemistry at UPenn and was an HHMI supported LSRF fellow at Cal Berkeley.
            </Typography>
            <Button size="small" href="https://www.linkedin.com/in/jack-f-b27a62206/" target="_blank"><LinkedInIcon fontSize="large" style={{ color:'#171941'}}/></Button>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item>
            <img src={Andrew} width='100%' alt="Jack Ferrie" style={{padding: '0% 20%', borderRadius:'50%'}}/>
            <Typography align='center' gutterBottom fontSize='1.5em' color='#171941' style={{padding: '10px'}}>
            <b>Andrew Apicello, CBO</b>
            </Typography>
            <Typography align='center' gutterBottom fontSize='1.2em' color='#171941' style={{padding: '10px'}}>
            Andrew Apicello has cultivated extensive business development experience across multiple startup ventures, developing and selling software to the worlds largest CROs, CDMOs, biotechs, and pharmaceutical firms.
            </Typography>
            <Button size="small" href="https://www.linkedin.com/in/andrewcapicello/" target="_blank"><LinkedInIcon fontSize="large" style={{ color:'#171941'}}/></Button>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item>
            <img src={Sam} width='100%' alt="Jack Ferrie" style={{padding: '0% 20%', borderRadius:'50%'}}/>
            <Typography align='center' gutterBottom fontSize='1.5em' color='#171941' style={{padding: '10px'}}>
            <b>Sam Giannakoulias, CTO</b>
            </Typography>
            <Typography align='center' gutterBottom fontSize='1.2em' color='#171941' style={{padding: '10px'}}>
            Sam Giannakoulias is an expert in life sciences computation, specifically machine learning and biomolecular simulation. He completed his PhD in Chemistry at UPenn, where he contributed to a drug discovery program which has entered Phase I clinical trials.
            </Typography>
            <Button size="small" href="https://www.linkedin.com/in/sam-giannakoulias-88637b213/" target="_blank"><LinkedInIcon fontSize="large" style={{ color:'#171941'}}/></Button>
            </Item>
          </Grid>
      </Grid>
    </Box>
    </Container>
    </Box>
  );
}
